.App {
  animation: fadeIn 2.0s ease forwards;
}

.mainText {
  pointer-events : none;
  position: absolute;
  top: 3vh;
  left: 7vw;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 0.1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.companyName {
  font-size: 17vmin;
  color: white;
}

.scrambler {
  display: flex;
  font-size: 5vmin;
  color: white;
  font-family: monospace;
}

.blinker {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.contactText {
  position: absolute;
  pointer-events: none;
  bottom: 6vh;
  left: 5vw;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: white;
}

ul {
  list-style-type: none;
}

a {
  pointer-events: auto;
  color: inherit;
  text-decoration: none;
}

[id^="icon"]{
  position: relative;
  top: 7px;
  right: 7px;
}

.particleBackground {
  background-color: #0080b2;
  display: block;
}

.lowpolyape {
  pointer-events : none;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  opacity: 0.5;
}